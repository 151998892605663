import { defineStore } from "pinia";

export const useCheckoutStore = defineStore("checkoutStore", {
  state: () => {
    return {
      step: useLocalStorage("webshop.checkout.step", "user"),
      guest_shopping: useLocalStorage("webshop.checkout.guest_shopping", null),
      comment: useLocalStorage("webshop.checkout.comment", null),
      newsletter_signup: useLocalStorage(
        "webshop.checkout.newsletter_signup",
        false,
      ),
      accept_legal: useLocalStorage("webshop.checkout.accept_legal", false),

      // person
      email: useLocalStorage("webshop.checkout.email", null),
      given_name: useLocalStorage("webshop.checkout.given_name", null),
      family_name: useLocalStorage("webshop.checkout.family_name", null),
      phone: useLocalStorage("webshop.checkout.phone", null),

      // shipping
      shipping_building: useLocalStorage(
        "webshop.checkout.shipping_building",
        null,
      ),
      shipping_city: useLocalStorage("webshop.checkout.shipping_city", null),
      shipping_comment: useLocalStorage(
        "webshop.checkout.shipping_comment",
        null,
      ),
      shipping_different_contact: useLocalStorage(
        "webshop.checkout.shipping_different_contact",
        false,
      ),
      shipping_diffcont_family_name: useLocalStorage(
        "webshop.checkout.shipping_diffcont_family_name",
        null,
      ),
      shipping_diffcont_given_name: useLocalStorage(
        "webshop.checkout.shipping_diffcont_given_name",
        null,
      ),
      shipping_diffcont_phone: useLocalStorage(
        "webshop.checkout.shipping_diffcont_phone",
        null,
      ),
      shipping_door: useLocalStorage("webshop.checkout.shipping_door", null),
      shipping_external_data: useLocalStorage(
        "webshop.checkout.shipping_external_data",
        "",
      ),
      shipping_external_id: useLocalStorage(
        "webshop.checkout.shipping_external_id",
        null,
      ),
      shipping_floor: useLocalStorage("webshop.checkout.shipping_floor", null),
      shipping_method: useLocalStorage(
        "webshop.checkout.shipping_method",
        null,
      ),
      shipping_postcode: useLocalStorage(
        "webshop.checkout.shipping_postcode",
        null,
      ),
      shipping_staircase: useLocalStorage(
        "webshop.checkout.shipping_staircase",
        null,
      ),
      shipping_store: useLocalStorage("webshop.checkout.shipping_store", null),
      shipping_street: useLocalStorage(
        "webshop.checkout.shipping_street",
        null,
      ),
      shipping_street_no: useLocalStorage(
        "webshop.checkout.shipping_street_no",
        null,
      ),
      shipping_street_type: useLocalStorage(
        "webshop.checkout.shipping_street_type",
        null,
      ),

      // billing
      billing_method: useLocalStorage("webshop.checkout.billing_method", null),
      // billing - company
      billing_company_building: useLocalStorage(
        "webshop.checkout.billing_company_building",
        null,
      ),
      billing_company_city: useLocalStorage(
        "webshop.checkout.billing_company_city",
        null,
      ),
      billing_company_company: useLocalStorage(
        "webshop.checkout.billing_company_company",
        null,
      ),
      billing_company_door: useLocalStorage(
        "webshop.checkout.billing_company_door",
        null,
      ),
      billing_company_floor: useLocalStorage(
        "webshop.checkout.billing_company_floor",
        null,
      ),
      billing_company_postcode: useLocalStorage(
        "webshop.checkout.billing_company_postcode",
        null,
      ),
      billing_company_staircase: useLocalStorage(
        "webshop.checkout.billing_company_staircase",
        null,
      ),
      billing_company_street: useLocalStorage(
        "webshop.checkout.billing_company_street",
        null,
      ),
      billing_company_street_no: useLocalStorage(
        "webshop.checkout.billing_company_street_no",
        null,
      ),
      billing_company_street_type: useLocalStorage(
        "webshop.checkout.billing_company_street_type",
        null,
      ),
      billing_company_vat: useLocalStorage(
        "webshop.checkout.billing_company_vat",
        null,
      ),
      // billing - private
      billing_private_building: useLocalStorage(
        "webshop.checkout.billing_private_building",
        null,
      ),
      billing_private_city: useLocalStorage(
        "webshop.checkout.billing_private_city",
        null,
      ),
      billing_private_door: useLocalStorage(
        "webshop.checkout.billing_private_door",
        null,
      ),
      billing_private_given_name: useLocalStorage(
        "webshop.checkout.billing_private_given_name",
        null,
      ),
      billing_private_floor: useLocalStorage(
        "webshop.checkout.billing_private_floor",
        null,
      ),
      billing_private_family_name: useLocalStorage(
        "webshop.checkout.billing_private_family_name",
        null,
      ),
      billing_private_postcode: useLocalStorage(
        "webshop.checkout.billing_private_postcode",
        null,
      ),
      billing_private_staircase: useLocalStorage(
        "webshop.checkout.billing_private_staircase",
        null,
      ),
      billing_private_street: useLocalStorage(
        "webshop.checkout.billing_private_street",
        null,
      ),
      billing_private_street_no: useLocalStorage(
        "webshop.checkout.billing_private_street_no",
        null,
      ),
      billing_private_street_type: useLocalStorage(
        "webshop.checkout.billing_private_street_type",
        null,
      ),

      // payment
      payment_method: useLocalStorage("webshop.checkout.payment_method", null),
    };
  },
  getters: {
    getStateAsObject: (state) => {
      const storeData: Record<string, any> = {};
      const stateKeys = Object.keys(state);
      for (const key in stateKeys) {
        if (
          !stateKeys[key].startsWith("$") &&
          !stateKeys[key].startsWith("_") &&
          !["reset", "getStateAsObject"].includes(stateKeys[key])
        ) {
          storeData[stateKeys[key]] = state[stateKeys[key]];
        }
      }
      return storeData;
    },
  },
  actions: {
    reset() {
      this.step = "user";
      this.guest_shopping = null;
      this.comment = null;
      this.newsletter_signup = false;
      this.accept_legal = false;
      this.email = null;
      this.given_name = null;
      this.family_name = null;
      this.phone = null;
      this.shipping_building = null;
      this.shipping_city = null;
      this.shipping_comment = null;
      this.shipping_diffcont_given_name = null;
      this.shipping_diffcont_family_name = null;
      this.shipping_diffcont_phone = null;
      this.shipping_different_contact = false;
      this.shipping_door = null;
      this.shipping_external_data = "";
      this.shipping_external_id = null;
      this.shipping_floor = null;
      this.shipping_method = null;
      this.shipping_postcode = null;
      this.shipping_staircase = null;
      this.shipping_store = null;
      this.shipping_street = null;
      this.shipping_street_no = null;
      this.shipping_street_type = null;
      this.billing_company_building = null;
      this.billing_company_city = null;
      this.billing_company_company = null;
      this.billing_company_door = null;
      this.billing_company_floor = null;
      this.billing_company_postcode = null;
      this.billing_company_staircase = null;
      this.billing_company_street = null;
      this.billing_company_street_no = null;
      this.billing_company_street_type = null;
      this.billing_company_vat = null;
      this.billing_method = null;
      this.billing_private_building = null;
      this.billing_private_city = null;
      this.billing_private_door = null;
      this.billing_private_given_name = null;
      this.billing_private_floor = null;
      this.billing_private_family_name = null;
      this.billing_private_postcode = null;
      this.billing_private_staircase = null;
      this.billing_private_street = null;
      this.billing_private_street_no = null;
      this.billing_private_street_type = null;
      this.payment_method = null;
    },
  },
});
